import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { useTranslation } from "react-i18next";
import Typewriter from "typewriter-effect";

export const Hero = () => {
    const [t] = useTranslation();
    return (
        <div className="bg-white">
            <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
                <div
                    aria-hidden="true"
                    className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
                />
                <div className="mx-auto max-w-7xl px-6 py-8 sm:py-16 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-8 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
                        <h1 className="max-w-2xl text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl lg:col-span-2 xl:col-auto">
                            Your Personalized
                            <span className="select-none">
                                <Typewriter
                                    options={{
                                        strings: ["Buff", "Couples", "Naruto"],
                                        autoStart: true,
                                        loop: true,
                                    }}
                                />
                            </span>
                            AI portraits
                        </h1>
                        <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                            <p className="text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
                                Headbot is the home of AI-generated portraits.
                                <br />
                                See some of our styles below and create your own unique portrait.
                            </p>
                        </div>

                        <StaticImage
                            className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
                            backgroundColor="transparent"
                            src="./hero-home.png"
                            alt={t("homePage.heroImageAlt")}
                            transformOptions={{ fit: "cover" }}
                            width={512}
                            objectPosition="50% 10%"
                            style={{ borderRadius: "4%", overflow: "hidden" }}
                            imgStyle={{ borderRadius: "4%" }}
                        />
                    </div>
                </div>
                <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
            </div>
        </div>
    );
};
